<template>
  <img :src="image" v-if="image" ref="image">
</template>

<script>
import ApiService from "../services/api";

export default {
  name: 'SectionImage',
  props: ['url', 'sectionId'],
  data() {
    return {
      image: null,
    }
  },
  computed: {
    page() {
      return this.$route.name
    },
  },
  methods: {
    orientation() {
      document.body.removeAttribute('orientation');
      setTimeout(() => {
        const w = this.$refs['image'].naturalWidth
        const h = this.$refs['image'].naturalHeight
        if (h > w) {
          document.body.setAttribute('orientation', 'portrait')
        } else if (h < w) {
          document.body.setAttribute('orientation', 'landscape')
        } else {
          document.body.setAttribute('orientation', 'even')
        }
      }, 500)
    }
  },
  created() {

    if (!this.url) {
      ApiService.getImage(`/projects/${this.$route.params.project_id}/sections/${this.sectionId}/image/`)
          .then((response) => {
            let image = btoa(
                new Uint8Array(response.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            this.image = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            if (this.page === 'Findings') {
              this.orientation()
            }

          });
    } else {
      let imagePath = this.url;
      if (location.hostname === 'localhost' && !imagePath.startsWith('http')) {
        imagePath = 'http://127.0.0.1:8000/' + imagePath
      }
      this.image = imagePath
    }

  }

};
</script>
