<template>
  <div class="magic-colors">
    <details class="magic-colors__item" v-for="(item, index) in items" :key="index" :open="index===0">
      <summary class="magic-colors__item__color" :style="{ backgroundColor: item.color }"></summary>
      <div class="magic-colors__item__matches">
        <div class="magic-colors__item__matches__item" v-for="(match, index) in item.matches" :key="index"
             :style="{ backgroundColor: match }"></div>
      </div>
    </details>
  </div>
</template>

<script>


export default {
  name: 'MagicColors',
  props: ['colors', 'type'],
  data() {
    return {
      items: []
    }
  },
  methods: {
    init() {
      const _colours = this.colors
      const colours = []

      const colourDiffs = this.colourDiffRgb

      Array.from(_colours).forEach(_colour => {
        colours.push(_colour)
      })

      const threshold = 0.08
      const reducedColours = {}

      let availableColours = colours.slice()

      while (availableColours.length > 0) {
        const colour = availableColours[0]

        reducedColours[colour] = [colour]

        availableColours.forEach(otherColour => {
          if (colour === otherColour) return

          const diff = colourDiffs(colour, otherColour)

          if (diff < threshold) {
            reducedColours[colour].push(otherColour)
          }
        })

        availableColours = availableColours.filter(x => !reducedColours[colour].includes(x))
      }

      const sorted = Object.keys(reducedColours)

      let colorList = []

      sorted.forEach(colour => {
        colorList.push({
          color: colour,
          matches: reducedColours[colour]
        })
      })

      this.items = colorList
      this.$parent.colorGroup[this.type] = colorList

    },
    colourDiffRgb(colour1, colour2) {
      const rgb1 = this.parseColour(colour1);
      const rgb2 = this.parseColour(colour2);

      let r = 255 - Math.abs(rgb1[0] - rgb2[0]);
      let g = 255 - Math.abs(rgb1[1] - rgb2[1]);
      let b = 255 - Math.abs(rgb1[2] - rgb2[2]);

      r /= 255;
      g /= 255;
      b /= 255;

      return 1 - ((r + g + b) / 3);
    },

    parseColour(colour) {
      let rgb;
      colour = colour.toLowerCase();
      rgb = this.parseHexColour(colour);
      return rgb
    },

    parseHexColour(colour) {
      const hex = parseInt(colour.substring(1), 16);

      const red = (hex & 0xff0000) >> 16;
      const green = (hex & 0x00ff00) >> 8;
      const blue = hex & 0x0000ff;

      return [red, green, blue];
    },
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },


};
</script>

<style lang="scss">
.magic-colors {

  .magic-colors__item {
    display: flex;
    flex-direction: column;

    margin: 0 0 20px 0;

    .magic-colors__item__color {
      width: 100%;
      height: 50px;
      border-radius: 3px;
    }

    .magic-colors__item__matches {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 10px;

      .magic-colors__item__matches__item {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}


details {
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  > summary {
    list-style-type: none;

    ::-webkit-details-marker {
      display: none;
    }
  }

  &[open] {
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  }

}


</style>
