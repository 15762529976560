<template>
  <div id="app">
    <alert v-if="error"></alert>
    <div id="preloader" v-if="loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <navbar v-if="showHeader"></navbar>
    <div :class="{' my-12 mx-auto px-4 md:px-12' : isFullGrid}">
      <router-view/>
    </div>

  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Alert from "./components/Alert";

export default {
  components: {
    Navbar,
    Alert
  },
  data() {
    return {}
  },
  computed: {
    loading() {
      return this.$store.getters['loading']
    },
    error() {
      return this.$store.getters['error']
    },
    page() {
      return this.$route.name
    },
    themeColor() {
      return this.$store.getters["theme"];
    },
    showHeader() {
      const withoutHeader = ['Presentation']
      return !withoutHeader.includes(this.page);
    },
    isFullGrid() {
      const fullPages = ['Findings', 'Contents', 'Presentation', 'Login', 'Register', 'Magic']
      return !fullPages.includes(this.page);
    }
  },
  watch: {
    themeColor: function (value) {
      localStorage.setItem('theme', value)
      if (value === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }
  },
  created() {
    const unAuthPages = ['Login', 'Register']
    if (!unAuthPages.includes(this.page)) {
      this.$store.dispatch('user');
    }

    if (this.themeColor === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }
}
</script>
<style lang="scss">

#preloader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
</style>
