<template>
  <div class="report-page" v-if="isReady">
    <div
        class="overflow-y-hidden relative flex justify-between text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul class="flex -mb-px">
        <li class="mr-2 whitespace-nowrap" v-for="tab in tabs" :key="tab.id" @click="currentTab = tab.id">
          <span class="inline-block p-4 border-b-2 border-transparent cursor-pointer"
                :class="{'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500' : currentTab === tab.id}"
          >{{ tab.title }}</span>
        </li>

      </ul>
      <div class="flex">
        <router-link :to="{ name: 'Presentation', params: { project_id: $route.params.project_id}, query: { 'print-pdf': 'true' }}" target="_blank"
                     class="flex mr-3 focus:outline-none leading-normal text-white rounded bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 px-5 py-2.5 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900">
          Print or Download
        </router-link>

        <router-link :to="{ name: 'Presentation', params: { project_id: $route.params.project_id}}" target="_blank"
                     class="flex focus:outline-none leading-normal text-white rounded bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
          <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"></path>
          </svg>
          Presentation Mode
        </router-link>
      </div>
    </div>

    <div v-show="currentTab === 'general'">

      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-4">
        <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <svg class="w-12 h-12 mr-3" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"></path>
          </svg>
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none text-gray-600">
                {{ report.sections.length }} Section
              </span>
              <h3 class="text-base font-light text-gray-400">Total Unique Section</h3>
            </div>
          </div>
        </div>
        <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <svg class="w-12 h-12 mr-3" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"></path>
          </svg>
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none text-gray-600">
                {{ findingsCount() }} Finding
              </span>
              <h3 class="text-base font-light text-gray-400">Total Unique</h3>
            </div>
          </div>
        </div>
        <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <svg class="w-12 h-12 mr-3" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"></path>
          </svg>
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl leading-none text-gray-600">
                {{ countByKeyInFindingNotNullOrEmpty('recommendation') }} Recommendation
              </span>
              <h3 class="text-base font-light text-gray-400">Total Unique Recommendation</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-6">
        <div class="grid gap-7 grid-cols-1 md:grid-cols-2">
          <div class=" p-5 bg-white rounded shadow-sm">
            <div class="font-bold text-gray-700 ">Status</div>
            <div class="flex items-center justify-between">
              <div class="flex flex-col pt-1 w-full">
                <div class=" font-medium mb-2 pb-2 border-b border-gray-100"
                     :style="'color:'+item.color+';'"
                     v-for="item in statuses" :key="'progress-'+item.id"
                >{{ countByKeyInFinding('progress', item.id) }} <span class="font-light">{{ item.title }}</span></div>
              </div>
              <div class="mt-3">
                <Pie :chart-data="progressChartData()" :chart-options="chartOptions" :width="200" :height="150"/>
              </div>
            </div>
          </div>
          <div class=" p-5 bg-white rounded shadow-sm">
            <div class="font-bold text-gray-700 ">Rating</div>
            <div class="flex items-center justify-between">
              <div class="flex flex-col pt-1 w-full">
                <div class=" font-medium mb-2 pb-2 border-b border-gray-100"
                     :style="'color:'+item.color+';'"
                     v-for="item in ratings" :key="'rating-'+item.id"
                >{{ countByKeyInFinding('rating', item.id) }} <span class="font-light">{{ item.title }}</span></div>
              </div>
              <div class="mt-3">
                <Pie :chart-data="ratingChartData()" :chart-options="chartOptions" :width="200" :height="150"/>
              </div>
            </div>
          </div>
          <div class=" p-5 bg-white rounded shadow-sm">
            <div class="font-bold text-gray-700 ">Sentiment</div>
            <div class="flex items-center justify-between">
              <div class="flex flex-col pt-1 w-full">
                <div class=" font-medium mb-2 pb-2 border-b border-gray-100"
                     :style="'color:'+item.color+';'"
                     v-for="item in sentiments" :key="'sentiment-'+item.id"
                >{{ countByKeyInFinding('sentiment', item.id) }} <span class="font-light">{{ item.title }}</span></div>
              </div>
              <div class="mt-3">
                <Pie :chart-data="sentimentChartData()" :chart-options="chartOptions" :width="200" :height="150"/>
              </div>
            </div>
          </div>
          <div class=" p-5 bg-white rounded shadow-sm">
            <div class="font-bold text-gray-700 ">Effort</div>
            <div class="flex items-center justify-between">
              <div class="flex flex-col pt-1 w-full">
                <div class=" font-medium mb-2 pb-2 border-b border-gray-100"
                     :style="'color:'+item.color+';'"
                     v-for="item in efforts" :key="'effort-'+item.id"
                >{{ countByKeyInFinding('effort', item.id) }} <span class="font-light">{{ item.title }}</span></div>
              </div>
              <div class="mt-3">
                <Pie :chart-data="effortChartData()" :chart-options="chartOptions" :width="200" :height="150"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="currentTab === 'categoryAndLabels'">
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Categories</h3>
            <Pie :chart-data="categoryChartData()" :chart-options="chartOptions"/>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in categoriesOrderedByCount()" :key="'category-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Labels</h3>
            <Pie :chart-data="labelsChartData()" :chart-options="chartOptions"/>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in labelsOrderedByCount()" :key="'label-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class="whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">All Categories</h3>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in categoriesAllOrderByCount()" :key="'category-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">All Labels</h3>
          </div>
          <table class="table-auto w-full mt-3">
            <tbody>
            <tr v-for="item in labelsAllWithCountOrderByCount()" :key="'labels-'+item.id">
              <td class="border-b border-gray-200 px-4 py-2">
                <span class="whitespace-no-wrap capitalize">
                  {{ item.title }}
                </span>
              </td>
              <td class="border-b border-gray-200 px-4 py-2 text-right">
                <span class=" whitespace-no-wrap">
                  {{ item.count }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Word Cloud from Findings</h3>
          </div>
          <wordcloud
              :data="findingUniqueWordWithCountAndObjectKey()"
              nameKey="text"
              valueKey="value"
              :showTooltip="true">
          </wordcloud>

        </div>
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Word Cloud from Categories</h3>
          </div>
          <wordcloud
              :data="categoriesWithCount()"
              nameKey="title"
              valueKey="count">
          </wordcloud>
        </div>
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="items-center">
            <h3 class="text-xl font-bold mb-3">Word Cloud from Labels</h3>
          </div>
          <wordcloud
              :data="labelsWithCount()"
              nameKey="title"
              valueKey="count">
          </wordcloud>
        </div>
      </div>
    </div>
    <div v-show="currentTab === 'findings'">
      <div class="mt-4 w-full grid grid-cols-1">
        <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="flex justify-end">
            <button class="hidden" @click="addFilter()">addFilter</button>
            <button @click="exportMe()" type="button"
                    class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
              <span class="whitespace-nowrap">Download Csv</span>
            </button>
          </div>
          <v-grid ref="vGrid" theme="material" :source="findings()"
                  :columns="columns"
                  :filter="true"
                  :col-size="150"
                  :auto-size="true"
                  :auto-size-column="true"
                  :readonly="true"
                  :resize="true"
                  :stretch="true"
                  :use-clipboard="true"
                  :exporting="true"
                  :grouping="true"
          ></v-grid>
        </div>
      </div>
    </div>
    <div v-show="currentTab === 'notification'">
      <div class="mt-4 w-full grid grid-cols-1">
        <div class="bg-white border-1 p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
          <div class="mb-5">You can specify different recipient or recipients for each tag used in the project. The
            default notification frequency is daily.
          </div>
          <div v-for="label in labelsAllWithCountOrderByCount()" :key="'form-labels-'+label.id" class="mb-10">
            <div>
              <label class="block text-gray-700 font-bold mb-2" for="email">
                <span
                    class="bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">#{{
                    label.title
                  }}</span>
              </label>
              <div class="main flex border overflow-hidden select-none text-sm mb-2">
                <label class="flex radio p-2 cursor-pointer">
                  <input class="my-auto transform scale-125" type="radio" :name="'form-labels-'+label.id" checked/>
                  <div class="title px-2 flex items-center">Daily</div>
                </label>

                <label class="flex radio p-2 cursor-pointer">
                  <input class="my-auto transform scale-125" type="radio" :name="'form-labels-'+label.id"/>
                  <div class="title px-2 flex items-center">Weekly</div>
                </label>

                <label class="flex radio p-2 cursor-pointer">
                  <input class="my-auto transform scale-125" type="radio" :name="'form-labels-'+label.id"/>
                  <div class="title px-2 flex items-center">Monthly</div>
                </label>
              </div>
              <textarea
                  class=" appearance-none border  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  id="email" name="email" placeholder=""></textarea>
            </div>

          </div>
          <div class="flex justify-end">
            <button
                class="flex focus:outline-none leading-normal text-white rounded bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-purple-300 px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import VGrid from "@revolist/vue-datagrid";
import {Pie} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)
import wordcloud from 'vue-wordcloud'


export default {
  data() {
    return {
      isReady: false,
      currentTab: 'general',
      tabs: [
        {id: 'general', title: 'General Stats'},
        {id: 'categoryAndLabels', title: 'Category & Labels'},
        {id: 'findings', title: 'Findings'}
      ],
      chartOptions: {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      },
      columns: [
        {
          prop: "category_str",
          name: "Category",
          sortable: true,
        },
        {
          prop: "labels_with_comma",
          name: "Labels",
        },
        {
          prop: "effort_str",
          name: "Effort",
          size: 100,
          sortable: true,
          cellTemplate: (createElement, props) => {
            const value = props.model[props.prop];
            return createElement(
                "span",
                {
                  class: {
                    "text-xs py-1 px-3 rounded-full": true,
                    "bg-red-200": value === "High",
                    "bg-blue-200": value === "Mid",
                    "bg-green-200": value === "Low",
                    "bg-gray-200": value === "None",
                  }
                },
                props.model[props.prop] || ""
            );
          }
        },
        {
          prop: "progress_str",
          name: "Progress",
          size: 130,
          sortable: true,
          cellTemplate: (createElement, props) => {
            const value = props.model[props.prop];
            return createElement(
                "span",
                {
                  class: {
                    "text-xs py-1 px-3 rounded-full": true,
                    "bg-gray-200": value === "Todo",
                    "bg-blue-200": value === "In Progress",
                    "bg-green-200": value === "Done",
                  }
                },
                props.model[props.prop] || ""
            );
          }
        },
        {
          prop: "rating_str",
          name: "Rating",
          size: 100,
          sortable: true,
          cellTemplate: (createElement, props) => {
            const value = props.model[props.prop];
            return createElement(
                "span",
                {
                  class: {
                    "text-xs py-1 px-3 rounded-full": true,
                    "bg-red-200": value === "Critical",
                    "bg-blue-200": value === "Major",
                    "bg-gray-200": value === "Minor",
                  }
                },
                props.model[props.prop] || ""
            );
          }
        },
        {
          prop: "sentiment_str",
          name: "Sentiment",
          size: 130,
          sortable: true,
          cellTemplate: (createElement, props) => {
            const value = props.model[props.prop];
            return createElement(
                "span",
                {
                  class: {
                    "text-xs py-1 px-3 rounded-full": true,
                    "bg-green-200": value === "Positive",
                    "bg-gray-200": value === "Neutral",
                    "bg-red-200": value === "Negative",
                  }
                },
                props.model[props.prop] || ""
            );
          }
        },
        {
          prop: "section_str",
          name: "Section",
        },
        {
          prop: "finding",
          name: "Finding",
        },
        {
          prop: "recommendation",
          name: "Recommendation",
        },
        {
          prop: "creator_full_name",
          name: "Creator",
        },
        {
          prop: "created_at",
          name: "Created At",
        },
      ]
    }
  },
  components: {
    Pie,
    VGrid,
    wordcloud
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    report() {
      return this.$store.getters['report/report'];
    },
    categories() {
      return this.$store.getters["tags/categories"];
    },
    labels() {
      return this.$store.getters["tags/labels"];
    },
    sentiments() {
      return this.$store.getters['sentiments'];
    },
    ratings() {
      return this.$store.getters['ratings'];
    },
    efforts() {
      return this.$store.getters['efforts'];
    },
    statuses() {
      return this.$store.getters['statuses'];
    }
  },
  methods: {
    addFilter() {
      const grid = this.$refs.vGrid.$el;
      grid.getPlugins().then(plugins => {
        plugins.forEach(p => {
          if (p.doFiltering) {
            p.multiFilterItems = {
              "category_str": [
                {
                  "id": 1,
                  "type": "contains",
                  "value": "con",
                  "relation": "and"
                }
              ],
              "progress_str": [
                {
                  "id": 3,
                  "type": "eq",
                  "value": "Done",
                  "relation": "and"
                }
              ]
            }
            p.runFiltering()
          }

        })
      });
    },
    exportMe() {
      const grid = this.$refs.vGrid.$el;
      grid.getPlugins().then(plugins => {
        plugins.forEach(p => {
          if (p.exportFile) {
            const exportPlugin = p;
            exportPlugin.exportFile({filename: this.report.title + ' Report'});
          }
        })
      });
    },
    findingUniqueWordWithCountAndObjectKey() {
      let words = [];
      this.findings().forEach(finding => {
        finding.finding.toLowerCase().split(' ').forEach(word => {
          let wordObj = words.find(w => w.text === word);
          if (wordObj) {
            wordObj.value += 1;
          } else {
            words.push({text: word, value: 1});
          }
        })
      })
      return words;
    },
    findings() {
      let findings = [];
      this.report.sections.forEach(section => {
        section.findings.forEach(finding => {
          finding['section_str'] = section.title;
          finding['labels_with_comma'] = finding.labels_str.join(',');
          findings.push(finding);
        });
      });
      return findings;
    },
    countByKeyInFinding: function (key, value) {
      return this.report.sections.reduce((acc, section) => {
        return acc + section.findings.reduce((acc, finding) => {
          return acc + (finding[key] === value ? 1 : 0)
        }, 0)
      }, 0)
    },
    countByKeyInFindingNotNullOrEmpty: function (key) {
      return this.report.sections.reduce((acc, section) => {
        return acc + section.findings.reduce((acc, finding) => {
          return acc + (finding[key] !== null && finding[key] !== '' ? 1 : 0)
        }, 0)
      }, 0)
    },
    colorsFromArray: function (key) {
      return this[key].map(item => item.color)
    },
    findingsCount() {
      let count = 0
      this.report.sections.forEach((item) => {
        count += item.findings.length
      })
      return count
    },
    randomHexColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },
    hexColorGeneratorXTimes: function (x) {
      let colors = []
      for (let i = 0; i < x; i++) {
        colors.push(this.randomHexColor())
      }
      return colors
    },
    sentimentChartData() {
      return {
        labels: [
          'Negative',
          'Neutral',
          'Positive'
        ],
        datasets: [{
          label: 'Sentiment',
          data: [
            this.countByKeyInFinding('sentiment', 1),
            this.countByKeyInFinding('sentiment', 2),
            this.countByKeyInFinding('sentiment', 3)
          ],
          backgroundColor: this.colorsFromArray('sentiments'),
          hoverOffset: 5,
        }]
      }
    },
    ratingChartData() {
      return {
        labels: [
          'Critical',
          'Major',
          'Minor'
        ],
        datasets: [{
          label: 'Rating',
          data: [
            this.countByKeyInFinding('rating', 1),
            this.countByKeyInFinding('rating', 2),
            this.countByKeyInFinding('rating', 3)
          ],
          backgroundColor: this.colorsFromArray('ratings'),
          hoverOffset: 5,
        }]
      }
    },
    effortChartData() {
      return {
        labels: [
          'None',
          'High',
          'Mid',
          'Low'
        ],
        datasets: [{
          label: 'Effort',
          data: [
            this.countByKeyInFinding('effort', 0),
            this.countByKeyInFinding('effort', 1),
            this.countByKeyInFinding('effort', 2),
            this.countByKeyInFinding('effort', 3)
          ],
          backgroundColor: this.colorsFromArray('efforts'),
          hoverOffset: 5,
        }]
      }
    },
    progressChartData() {
      return {
        labels: [
          'Todo',
          'In Progress',
          'Done'
        ],
        datasets: [{
          label: 'Status',
          data: [
            this.countByKeyInFinding('progress', 10),
            this.countByKeyInFinding('progress', 20),
            this.countByKeyInFinding('progress', 30),
          ],
          backgroundColor: this.colorsFromArray('statuses'),
          hoverOffset: 5,
        }]
      }
    },
    categoriesAllWithCount() {
      let categories = []
      this.categories.forEach((category) => {
        let count = this.countByKeyInFinding('category', category.id)
        categories.push({
          id: category.id,
          title: category.title,
          count: count
        })
      })
      return categories
    },
    categoriesAllOrderByCount() {
      return this.categoriesAllWithCount().sort((a, b) => {
        return b.count - a.count
      })
    },
    categoriesWithCount() {
      let categories = []
      this.categories.forEach((category) => {
        let count = this.countByKeyInFinding('category', category.id)
        if (count > 0) {
          categories.push({
            id: category.id,
            title: category.title,
            count: count
          })
        }
      })
      return categories
    },
    valuesFromCategoriesWithCount(key) {
      return this.categoriesWithCount().map(item => item[key])
    },
    categoriesOrderedByCount() {
      return this.categoriesWithCount().sort((a, b) => b.count - a.count)
    },
    categoryChartData() {
      return {
        labels: this.valuesFromCategoriesWithCount('title'),
        datasets: [{
          label: 'Category',
          data: this.valuesFromCategoriesWithCount('count'),
          backgroundColor: this.hexColorGeneratorXTimes(this.categories.length),
          hoverOffset: 5,
        }]
      }
    },
    labelsAllWithCount() {
      let labels = []
      this.labels.forEach((label) => {
        let count = this.report.sections.reduce((acc, section) => {
          return acc + section.findings.reduce((acc, finding) => {
            return acc + (finding.labels.includes(label.id) ? 1 : 0)
          }, 0)
        }, 0)

        labels.push({
          id: label.id,
          title: label.title,
          count: count
        })

      })
      return labels
    },
    labelsAllWithCountOrderByCount() {
      return this.labelsAllWithCount().sort((a, b) => {
        return b.count - a.count
      })
    },
    labelsWithCount() {
      let labels = []
      this.labels.forEach((label) => {
        let count = this.report.sections.reduce((acc, section) => {
          return acc + section.findings.reduce((acc, finding) => {
            return acc + (finding.labels.includes(label.id) ? 1 : 0)
          }, 0)
        }, 0)
        if (count > 0) {
          labels.push({
            id: label.id,
            title: label.title,
            count: count
          })
        }
      })
      return labels
    },
    labelsOrderedByCount() {
      return this.labelsWithCount().sort((a, b) => b.count - a.count)
    },
    labelsChartData() {
      return {
        labels: this.labelsWithCount().map(item => item.title),
        datasets: [{
          label: 'labels',
          data: this.labelsWithCount().map(item => item.count),
          backgroundColor: this.hexColorGeneratorXTimes(this.labels.length),
          hoverOffset: 5,
        }]
      }
    },
  },
  async created() {
    await this.$store.dispatch('report/get', {
      project_id: this.$route.params.project_id,
    })
    await this.$store.dispatch('projects/get', {
      project_id: this.$route.params.project_id,
    })
    await this.$store.dispatch('tags/init', {
      project_id: this.$route.params.project_id,
    })
    this.$store.commit('SET_LOADING', false, {root: true})
    this.isReady = true;
  },

};
</script>
<style lang="scss">
.report-page {
  revo-grid {
    height: 500px;
    font-family: unset;
  }

  .report {
    h2, .h2 {
      font-size: 18px;
    }

    h5, .h5 {
      font-size: 13px;
    }

    .body {
      padding-top: 40px;
      position: relative;
    }
  }

  .sub-title {
    margin: 30px 0 0 0;
  }

  .nav-header {
    position: absolute;
    top: 71px;
    left: 0;
    right: 0;
    background: white;
    padding: 15px 15px 0 15px;
    border-bottom: 1px solid #e0e0e0;
    z-index: 9;

    .nav {
      border-bottom: 0;
      @media (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .nav-item {
        white-space: nowrap;
      }

      .nav-link {
        cursor: pointer;
        color: rgba(120, 85, 244, 0.5);
        font-weight: 700;
        position: relative;

        &.active {
          background-color: #f7faff;
          color: #7855f4;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            width: 100%;
            background-color: #f7fafe;
            bottom: -1px;
            z-index: 9999;
          }
        }
      }
    }
  }


  hr {
    margin: 30px 0 20px 0;
    display: inline-block;
    width: 100%;
  }

  .screen-and-finding {
    display: flex;
  }

  .screen {
    background-color: white;
    padding: 30px;
    margin: 30px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    //box-shadow: rgba(50, 50, 93, 0.1) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.0) 0px 18px 36px -18px inset;
    .download-screen {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 12px;
      cursor: pointer;
    }

    .watermark {
      position: absolute;
      right: 30px;
      top: 30px;

      img {
        height: 50px;
      }
    }

    @media (max-width: 768px) {
      padding: 15px;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
    }

    &__image {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &__img {
      width: 300px;
      box-shadow: rgba(0, 0, 0, 0.15) 0 13px 27px -5px, rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }

  .findings {
    padding: 0 30px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .finding__group {
    $self: &;

    &__title {
      font-family: 'Oswald', sans-serif;
      color: #7954f3;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 20px;
      margin: 0 0 10px 0;
    }

    &__list {
      @media (max-width: 768px) {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        margin-bottom: 20px;
        @media (max-width: 768px) {
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &:last-child {
          @media (max-width: 768px) {
            border: none;
          }
        }
      }
    }

    &.negative {
      #{$self}__title {
        color: #F24913;
      }
    }

    &.positive {
      #{$self}__title {
        color: #9fcd4b;
      }
    }

    &.recommendation {
      #{$self}__title {
        color: #355CD6;
      }
    }
  }

  .card-stats {
    @media (max-width: 768px) {
      margin: 20px 0 0 0;
    }

    .card-body {
      padding: 0;
    }
  }

  .effort {
    font-size: 13px;

    &:before {
      content: '-'
    }
  }

  .severity {
    display: inline-block;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: white;
    padding: 1px 5px;
    position: relative;
    top: -2px;
    text-align: center;

    &.high {
      background-color: rgba(242, 73, 19, 0.75);
    }

    &.mid {
      background-color: rgba(242, 73, 19, 0.5);
    }

    &.low {
      background-color: rgba(242, 73, 19, 0.25);
    }
  }

  .criteria {
    display: inline-block;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #607d8b;
    padding: 1px 5px;
    position: relative;
    top: -2px;
    text-align: center;
  }

  .rgHeaderCell .rv-filter {
    opacity: 1 !important;
  }

  revogr-header .rgHeaderCell .header-content {
    font-weight: 400;
  }

  .rgRow {
    font-weight: 300;
  }
}
</style>
