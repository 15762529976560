import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'));

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.component('multiselect', Multiselect)

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)
Vue.filter('formatSize', function (size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB'
    }
    return size.toString() + ' B'
})

import './assets/stylesheets/index.css'
import './assets/stylesheets/app.scss'

Vue.config.productionTip = false

import ApiService from "./services/api";

ApiService.init();

window.token = localStorage.getItem('token');

function checkLogin() {
    const token = localStorage.getItem('token')
    if (token) {
        return true
    } else {
        localStorage.clear();
        return false
    }
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !checkLogin()) {
        next({path: '/login', query: {redirect: to.fullPath}});
    } else {
        next();
    }
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
