<template>
  <div class="presentation" v-if="isReady">
    <div class="reveal">
      <div class="slides">
        <section class="" v-for="section in report" :key="section.id">
          <section class="findings" v-for="finding in section.findings" :key="finding.id" :data-axis_x="finding.axis_x"
                   :data-axis_y="finding.axis_y">
            <div class="section-item">
              <div class="section-image">
                <div class="screen__name">
                  {{ section.title }}
                </div>
                <div class="relative">
                  <span class="finding-pin" :style="'left:'+axis_x+'%; top:'+axis_y+'%;'"></span>
                  <SectionImage :url="section.image" :section-id="section.id"
                                class="bg-white border p-4 dark:bg-gray-800 dark:border-gray-700"></SectionImage>
                </div>

              </div>
              <div class="findingItem">
                <div>
                  <div class="report-title">Finding</div>
                  <div class="finding"> {{ finding.finding }}</div>
                </div>
                <div>
                  <div class="report-title">Recommendation</div>
                  <div class="recommendation">{{ finding.recommendation }}</div>
                </div>
                <div class="info">
                  <div class="info__item">
                    <div class="info__title">Rating</div>
                    <div class="info__value">

                      <div class="rating" :data-rating="finding.rating_str.toLowerCase()">
                        <svg v-for="i in 3" :key="i" class="w-5 h-5" fill="rgba(0,0,0,0.5)" stroke="transparent"
                             viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"
                                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path>
                        </svg>
                      </div>
                      <div class="ml-1">{{ finding.rating_str }}</div>


                    </div>
                  </div>
                  <div class="info__item">
                    <div class="info__title">Sentiment</div>
                    <div class="info__value">
                      <span :class="'sentiment ' + finding.sentiment_str.toLowerCase()"></span>
                      <span class="ml-1">{{ finding.sentiment_str }}</span>
                    </div>
                  </div>
                  <div class="info__item">
                    <div class="info__title">Category</div>
                    <div class="info__value">{{ finding.category_str }}</div>
                  </div>
                  <div class="info__item hidden">
                    <div class="info__title">Labels</div>
                    <div class="info__value">
                      <span v-for="(label,index) in finding.labels_str" :key="'label-'+ index">{{ label }}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </section>
      </div>
    </div>
  </div>
</template>
<script>

import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";

import SectionImage from "../components/SectionImage";


export default {
  name: 'Presentation',
  props: ['sections'],
  components: {
    SectionImage
  },
  data() {
    return {
      isReady: false,
      axis_x: null,
      axis_y: null,
    }
  },
  computed: {
    report() {
      let data = this.$store.getters['report/report'];
      data = data.sections.filter((section) => section.findings.length > 0)
      return data;
    },
  },
  methods: {
    changePinAxis(x, y) {
      this.axis_x = x;
      this.axis_y = y;
    }
  },
  async created() {
    await this.$store.dispatch('report/get', {
      project_id: this.$route.params.project_id,
    })
    this.$store.commit('SET_LOADING', false, {root: true})
    this.isReady = true;
    this.$nextTick(() => {
      Reveal.initialize({
        width: '100%',
        height: '100%',
        pdfMaxPagesPerSlide: 1,
        pdfSeparateFragments: false
      });

      Reveal.on('slidechanged', (event) => {
        this.changePinAxis(event.currentSlide.dataset.axis_x, event.currentSlide.dataset.axis_y)
      });

      Reveal.on('ready', (event) => {
        this.changePinAxis(event.currentSlide.dataset.axis_x, event.currentSlide.dataset.axis_y)
      });
    })

  },
};
</script>
<style lang="scss" scoped>
.scrollable {
  bottom: 0;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.presentation {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  * {
    text-align: left;

  }
}

.report-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.screen__name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}

.section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 100%;
    max-height: 80vh;
  }
}

.findings {
  line-height: normal;

  .findingItem {
    flex: 1 0 50%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .finding {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 40px;
    }


    .recommendation {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      flex-direction: column;

      &__item {
        margin: 30px 0 0 0;
      }

      &__title {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 16px;
      }

      &__value {
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
      }
    }
  }

  .rating {
    display: flex;
  }

  .sentiment {
    display: inline;
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;

    &.positive {
      background-color: #4caf50;
    }

    &.neutral {
      background-color: #ff9800;
    }

    &.negative {
      background-color: #f44336;
    }
  }

}

.section-item {
  display: flex;
  height: 100vh;
}

[data-rating="critical"] {
  svg {
    fill: red;
  }
}

[data-rating="major"] {

  svg {
    &:nth-child(1), &:nth-child(2) {
      fill: red;
    }
  }
}

[data-rating="minor"] {
  svg {
    &:nth-child(1), {
      fill: red;
    }
  }
}

.finding-pin {
  position: absolute;
  width: 30px;
  height: 30px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  background: #e91e63;
  border: 1px solid rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
}

.finding-pin:before,
.finding-pin:after {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  left: -6px;
  top: -6px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #e91e63;
  animation: active 4s infinite linear;
}

.finding-pin:after {
  animation-delay: 1s;
}


@keyframes active {
  0% {
    transform: scale(.1);
    transform: box-shadow(0px 0px 0px 1px #e91e63);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
</style>

