<template>
  <transition>
    <!-- Main modal -->
    <div v-if="isShow" tabindex="-1" aria-hidden="true"
         class="customModal flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
      <div class="relative w-full h-full max-w-2xl md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white border dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 border-b dark:border-gray-600">
            <h3 class="text-xl font-medium text-gray-900 dark:text-white">
              Members
            </h3>
            <button
                @click="closeModal"
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal">
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">

            <div>
              <div class="flex items-end">
                <div class="flex-1">
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email
                    Address</label>
                  <input v-model="form.email" type="email" name="email" id="email"
                         class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                         placeholder="name@company.com" required>
                </div>
                <div class="ml-2">
                  <button @click="submit()" type="submit"
                          class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Add
                  </button>
                </div>
              </div>
              <div>

                <div class="container mx-auto">
                  <div class="">

                    <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                      <div class="inline-block min-w-full  overflow-hidden">
                        <table class="min-w-full leading-normal">
                          <thead class="whitespace-nowrap">
                          <tr>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              E-Mail
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              Name
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              Create Date
                            </th>
                            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
                          </tr>
                          </thead>
                          <tbody v-if="items">
                          <tr v-for="item in items" :key="item.id">
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {{ item.email }}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">{{ item.first_name }} {{ item.last_name }}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {{ item.created_at | moment('DD/MM/YYYY') }}</p>
                            </td>

                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right">
                              <button
                                  @click="remove(item.id)"
                                  type="button"
                                  class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium py-2 px-3 text-xs mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 ">
                                DELETE
                              </button>
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    isShow() {
      return this.$store.getters['members/isShow']
    },
    items() {
      return this.$store.getters['members/list']
    }

  },
  methods: {
    closeModal() {
      this.$store.commit('members/SET_IS_SHOW', false)
      this.$store.dispatch('projects/list')
    },
    async submit() {
      let params = new FormData();
      params.append('email', this.form.email);
      await this.$store.dispatch('members/create', params)
      this.form.email = ''
    },
    async remove(id) {
      await this.$store.dispatch('members/delete', {
        member_id: id
      })
    },
  },
};
</script>
<style lang="scss">

</style>

