import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        list: []
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
    },
    actions: {
        init({dispatch}, {project_id, section_id}) {
            dispatch('list', {project_id, section_id});
        },
        list({commit}, {project_id, section_id}) {
            commit('SET_LOADING', true, {root: true})
            ApiService.get(`/projects/${project_id}/sections/${section_id}/interviews/`).then((res) => {
                commit("SET_LIST", res.data.result);
                commit('SET_LOADING', false, {root: true})
            })
        },
        async create({commit, dispatch}, payload) {

            payload['params']['labels'] = payload.params.labels ? payload.params.labels.map((item) => item.id) : 0;
            commit('SET_ERROR', null, {root: true})
            return await ApiService.post(`/projects/${payload.project_id}/sections/${payload.section_id}/interviews/`, payload.params).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async update({commit, dispatch}, payload) {
            payload['params']['labels'] = payload.params.labels.map((item) => item.id);

            commit('SET_ERROR', null, {root: true})
            return await ApiService.patch(`/projects/${payload.project_id}/sections/${payload.section_id}/interviews/${payload.content_id}/`, payload.params).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async delete({commit, dispatch}, {project_id, section_id, content_id}) {
            commit('SET_LOADING', true, {root: true})
            return await ApiService.delete(`/projects/${project_id}/sections/${section_id}/interviews/${content_id}`).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', {project_id, section_id})
                }
                return res
            })
        },
    },
    getters: {
        list(state) {
            return state.list;
        }
    }
};
