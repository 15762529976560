import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '../views/Projects'
import Sections from '../views/Sections'
import Findings from '../views/Findings'
import Contents from '../views/Contents'
import Report from '../views/Report'
import Presentation from '../views/Presentation.vue'

import Login from '../views/Auth/Login'
import Register from '../views/Auth/Register'
import Plans from '../views/Plans.vue'

import DesignReportsList from "../views/DesignReports/List.vue";
import DesignReportsDetail from "../views/DesignReports/Detail.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register/',
        name: 'Register',
        component: Register,
    },
    {
        path: '/plans/',
        name: 'Plans',
        component: Plans,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Projects,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/:project_id/sections/',
        name: 'Sections',
        component: Sections,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/:project_id/sections/:section_id/findings/',
        name: 'Findings',
        component: Findings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/:project_id/sections/:section_id/contents/',
        name: 'Contents',
        component: Contents,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/:project_id/reports/',
        name: 'Report',
        component: Report,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/projects/:project_id/presentation/',
        name: 'Presentation',
        component: Presentation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/design-reports/',
        name: 'DesignReportsList',
        component: DesignReportsList,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/design-reports/:report_id/',
        name: 'DesignReportsDetail',
        component: DesignReportsDetail,
        meta: {
            requiresAuth: false
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
