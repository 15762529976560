<template>
  <div data-page-design-reports>
    <image-upload></image-upload>

    <div class="flex flex-wrap lg:-mx-4">
      <div class=" my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3" v-for="item in items" :key="item.id">
        <div
            class="item flex flex-col border-b bg-white p-10 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <router-link
              :to="{ name:  'DesignReportsDetail' , params: {  report_id: item.id}}"
              class="text-base font-light dark:text-white mb-4 h-60 max-h-60 overflow-hidden">
            <SectionImage :url="item.image" :section-id="item.id"></SectionImage>
          </router-link>
          <div class="flex flex-col justify-between">
            <router-link
                :to="{ name:'DesignReportsDetail', params: { report_id: item.id}}">
              <div class="text-lg text-gray-700 md:text-xl dark:text-white font-medium">{{ item.title }}</div>
            </router-link>
            <div class="relative">
              <div class="flex items-center">
                <div class="dark:text-gray-400">
                  <div class="text-xs font-light">{{ item.creator_full_name }}, {{
                      item.created_at | moment('calendar')
                    }}
                  </div>
                </div>
              </div>
              <div class="absolute right-1 bottom-2">
                <span
                    @click="destroy(item.id)"
                    class="remove-button bg-red-700 text-white text-xs px-2 py-1 dark:bg-red-700 dark:text-gray-300 ml-2 cursor-pointer rounded">DELETE</span>
                <button @click="edit(item)"
                        class="whitespace-nowrap text-black border text-xs  px-2 py-1 dark:bg-blue-700 dark:text-gray-300 ml-2 cursor-pointer rounded">
                  EDIT
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <transition>
      <!-- Main modal -->
      <div v-if="showEditModal" tabindex="-1" aria-hidden="true"
           class="customModal flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full h-full max-w-2xl md:h-auto">
          <!-- Modal content -->
          <div class="relative bg-white border dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b dark:border-gray-600">
              <h3 class="text-xl font-medium text-gray-900 dark:text-white">
                Edit
              </h3>
              <button
                  @click="showEditModal = false"
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">

              <div>
                <div class="space-y-4 md:space-y-6">
                  <input
                      v-model="selected.title"
                      type="text"
                      placeholder="Edit title"
                      class="rounded bg-gray-50 border border-gray-100 text-gray-900 sm:text-sm focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-1"
                  >

                </div>
                <div class="mt-5">
                  <button
                      @click="update()"
                      :disabled="!editFormIsValid"
                      type="button"
                      class="rounded w-full py-3 px-5 text-base font-medium text-center text-white bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      :class="{'bg-gray-600 hover:bg-gray-800 cursor-pointer' : editFormIsValid}">
                    UPDATE
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>

import SectionImage from "../../components/SectionImage";
import ImageUpload from "../../components/DesignReports/ImageUpload";

export default {
  name: 'DesignReportsList',
  components: {SectionImage, ImageUpload},
  data() {
    return {
      showNewModal: false,
      showEditModal: false,
      selected: null,
      form: {
        title: '',
        image: null,
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    items() {
      return this.$store.getters["design_reports/list"];
    },
    section() {
      return this.$store.getters["design_reports/current"];
    },
    formIsValid() {
      return this.form.title.length > 0 && this.form.image

    },
    editFormIsValid() {
      return this.selected && this.selected.title.length > 0
    },
  },
  methods: {
    async submit() {
      let params = new FormData();
      params.append('title', this.form.title);
      params.append('image', this.form.image);


      await this.$store.dispatch('design_reports/create', {
        params: params,
      })
      this.showNewModal = false;
      this.form.title = ''
      this.form.image = ''
      this.$refs.file.value = null;
      document.getElementById('preview').innerHTML = '';


    },
    edit(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.showEditModal = true;
    },
    async update() {
      const {data} = await this.$store.dispatch('design_reports/update', {
        title: this.selected.title,
        report_id: this.selected.id
      })
      if (data.status !== 'error') {
        this.showEditModal = false;
        this.selected = null
      }
    },
    uploadFile() {
      this.form.image = this.$refs.file.files[0];
      let reader = new FileReader()
      reader.readAsDataURL(this.$refs.file.files[0])
      reader.onloadend = function () {
        let img = document.createElement('img')
        img.src = reader.result;
        document.getElementById('preview').innerHTML = '';
        document.getElementById('preview').append(img)
      }
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('design_reports/delete', {
            report_id: id,
          })
        }
      })


    },
  },
  created() {
    this.$store.dispatch('design_reports/init')
  }
}
</script>
<style lang="scss" scoped>
[data-page-design-reports] {
  .remove-button {
    display: none;
  }

  .item:hover {
    .remove-button {
      display: inline-block;
    }
  }
}
</style>
