<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <h2 class="mt-6 text-center text-5xl tracking-tight text-gray-900 dark:text-gray-200 mb-10">
        <span class="font-light">UX</span>
        <span class="font-thin">REPORT</span>
      </h2>
      <div
          class="w-full bg-white border-b dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Sign in to your account
          </h1>
          <div
              v-for="(items,title) in error"
              :key="title"
              class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert">
            <div class="capitalize" v-if="title !=='non_field_errors'">{{ title }}</div>
            <p v-for="(item, index) in items" :key="index">{{ item }}</p>
          </div>
          <form class="space-y-4 md:space-y-6" action="#" @submit.prevent="submit">
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
              <input type="email" name="email" id="email"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="name@company.com"
                     v-model="form.email"
                     required
              >
            </div>
            <div>
              <label for="password"
                     class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input type="password" name="password" id="password" placeholder="••••••••"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     v-model="form.password"
                     required
              >
            </div>
            <div class="flex items-center justify-between hidden">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox"
                         class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                  >
                </div>
                <div class="ml-3 text-sm">
                  <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
              </div>
              <!--              <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>-->
            </div>
            <button type="submit"
                    class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
              Sign in
            </button>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet?
              <router-link to="register"
                           class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign
                up
              </router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ApiService from "../../services/api";
import JwtService from "../../services/jwt";

export default {
  name: "Login",
  data() {
    return {
      error: null,
      rememberMe: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    submit() {

      const self = this;
      self.error = null;
      self.$store.commit("SET_LOADING", true);

      ApiService.post("auth/login/", self.form).then((res) => {
        const token = res.data.result.access_token;
        JwtService.saveToken(token);
        let goTo = "/";
        if (self.$route.query.redirect) {
          goTo = self.$route.query.redirect;
        }
        window.location = goTo;
      })
          .catch((err) => {
            self.error = err.response.data.result;
            self.$store.commit("SET_LOADING", false);
          })
    }
  },
  created() {
    window.localStorage.clear()
    this.$store.commit("SET_LOADING", false);
  }
};
</script>

<style scoped>

</style>
