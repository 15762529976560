<template>
  <div data-page-projects v-if="user">

    <router-link :to="{name:'DesignReportsList'}" class="relative isolate flex items-center overflow-hidden bg-purple-500 p-10 sm:before:flex-1 text-white rounded mb-3">
      <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
      </div>
      <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"></div>
      </div>
      <div class="flex flex-wrap flex-col gap-x-4 gap-y-2">
        <p class="text-xl leading-6">
          <strong class="font-semibold">New Feature: Screenshot Analysis!</strong>
        </p>
        <p>
          Upload your mobile app screenshots, get automatic element analysis and statistics.
        </p>
      </div>
      <div class="flex flex-1 justify-end">
        <span class="flex-none rounded-full bg-white px-10 py-4 text-md font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Try it out now<span aria-hidden="true" class="ml-2">&rarr;</span></span>
      </div>
    </router-link>


    <div class="flex flex-wrap -mx-1 lg:-mx-4">
      <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
        <div
            class=" bg-white dark:border-gray-700 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div class="flex flex-col justify-between p-10 h-80  max-h-80 ">
            <div>
              <h1 class="mb-3 text-xl font-light leading-tight tracking-tight text-gray-900 dark:text-white">
                Create a new project
              </h1>
              <div class="space-y-4 md:space-y-6">
                <input
                    v-model="form.title"
                    type="text" id="title"
                    placeholder=""
                    class="rounded bg-gray-50 border border-gray-100 text-gray-900 sm:text-sm focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-1"
                >
                <div class="flex items-center">
                  <div class="flex items-center mr-4" v-for="item in projectTypes" :key="item.id">
                    <input :disabled="item.disabled" :id="'project-type-'+ item.id" type="radio" v-model="form.type"
                           name="type" :value="item.id"
                           class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'project-type-'+ item.id"
                           :disabled="item.disabled"
                           class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                  @click="submit()"
                  :disabled="!formIsValid"
                  type="button"
                  class="rounded w-full py-3 px-5 text-base font-medium text-center text-white bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  :class="{'bg-gray-600 hover:bg-gray-800 cursor-pointer' : formIsValid}">
                CREATE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
          class="item relative my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3" v-for="item in items"
          :key="item.id">

        <div
            class="flex flex-col justify-between border-b bg-white p-10 h-80 max-h-80 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <div class="flex justify-between">
            <router-link :to="{ name: 'Sections', params: { project_id: item.id}}">
              <div class="text-lg text-gray-700 md:text-xl dark:text-white font-medium">{{ item.title }}</div>
              <div class="font-thin text-sm dark:text-white mb-1 ">{{ item.type_str }}</div>
              <div class="font-thin text-xs mb-3">
                <div class="flex items-center dark:text-white">
                  {{ item.created_at | moment('DD-MM-YYYY') }},    {{ item.owner_full_name }}
                </div>

              </div>
              <div class="leading-5 flex items-baseline font-light text-xs dark:text-gray-400 ">
                <div class="flex items-center mb-1 mr-2">
                  <svg class="w-4 h-4 mr-1" fill="none"
                       stroke="rgba(0,0,0,0.5)"
                       stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"></path>
                  </svg>
                  {{ item.sections_count }} {{ item.type === 1 ? 'Personas' : 'Screen' }}
                </div>
                <div class="flex items-center mb-1 mr-2">
                  <svg class="w-4 h-4 mr-1" fill="none" stroke="rgba(0,0,0,0.6)" stroke-width="1.5" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6"></path>
                  </svg>
                  {{ item.findings_count }} {{ item.type === 1 ? 'Content' : 'Finding' }}
                </div>

              </div>
            </router-link>
            <div class="flex-col items-end  font-thin text-xs">

              <div class="flex justify-end">
                <div class="dropdown inline-block relative cursor-pointer" v-if="!isFreemium">
                  <svg class="w-6 h-6" fill="none" stroke="rgba(0,0,0,0.6)" stroke-width="1.5" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"></path>
                  </svg>
                  <ul class="dropdown-menu absolute right-0 hidden text-gray-700 whitespace-nowrap shadow">
                    <li @click="edit(item)" class="bg-white hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap">Edit
                      Title
                    </li>
                    <li v-if="item.owner === user.user.id" @click="destroy(item.id)"
                        class="bg-white hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap">Delete Project
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div v-if="item.findings_count > 0" class="border-b pb-5 dark:border-gray-600">
            <div class="text-sm font-thin mb-1 dark:text-white">{{ item.done_count }} out of {{ item.findings_count }}
              {{ item.type === 1 ? 'content' : 'finding' }} were completed
            </div>
            <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
              <div class="h-1.5 bg-green-600 rounded-full"
                   :style="`width:${(item.done_count / item.findings_count) * 100}%`"></div>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div class="actions">
              <router-link :to="{ name: 'Report', params: { project_id: item.id}}">
                <button
                    class="flex px-3 py-2 text-xs   text-center text-gray-500 bg-white border rounded hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-gray-100 dark:hover:bg-purple-700 dark:focus:ring-purple-800 mr-2 ">
                  <svg  class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"></path>
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"></path>
                  </svg>
                  SHOW REPORT
                </button>
              </router-link>
            </div>
            <template v-if="item.owner === user.user.id && !isFreemium">
              <div class="flex cursor-pointer" @click="showMembers(item.id)">
                <div v-for="member in item.members" :key="member.id"
                     :title="member.first_name +' '+ member.last_name"
                     class="hidden inline-flex -ml-2 items-center justify-center w-10 h-10 bg-indigo-50 text-md font-large text-gray-500 border dark:bg-gray-700 dark:text-white">
                  {{ member.first_name.split('')[0] }}
                </div>
                <div>
                  <button
                      class="flex px-3 py-2 text-xs text-gray-600 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path clip-rule="evenodd" fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"></path>
                      <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"></path>
                    </svg> MEMBERS
                  </button>
                </div>
                <div
                    class="hidden inline-flex rounded items-center justify-center w-10 h-10 border text-md font-large text-white  dark:bg-gray-700 dark:text-white">
                  <svg class="w-5 h-5" fill="none" stroke="gray" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                  </svg>
                </div>
              </div>
            </template>
          </div>

        </div>


      </div>

    </div>

    <members></members>

    <transition>
      <!-- Main modal -->
      <div v-if="showEditModal" tabindex="-1" aria-hidden="true"
           class="customModal flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full h-full max-w-2xl md:h-auto">
          <!-- Modal content -->
          <div class="relative bg-white border dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b dark:border-gray-600">
              <h3 class="text-xl font-medium text-gray-900 dark:text-white">
                Edit
              </h3>
              <button
                  @click="showEditModal = false"
                  type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">

              <div>
                <div class="space-y-4 md:space-y-6">
                  <input
                      v-model="selected.title"
                      type="text"
                      placeholder="Edit title"
                      class="rounded bg-gray-50 border border-gray-100 text-gray-900 sm:text-sm focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-1"
                  >

                </div>
                <div class="mt-5">
                  <button
                      @click="update()"
                      :disabled="!editFormIsValid"
                      type="button"
                      class="rounded w-full py-3 px-5 text-base font-medium text-center text-white bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      :class="{'bg-gray-600 hover:bg-gray-800 cursor-pointer' : editFormIsValid}">
                    UPDATE
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import Members from "../components/Members";

export default {
  name: 'Projects',
  components: {
    Members,
  },
  data() {
    return {
      showNewModal: false,
      showEditModal: false,
      selected: null,
      form: {
        title: '',
        type: 2
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    items() {
      return this.$store.getters["projects/list"];
    },
    projectTypes() {
      return this.$store.getters["projectTypes"];
    },
    formIsValid() {
      return this.form.title.length > 0
    },
    editFormIsValid() {
      return this.selected && this.selected.title.length > 0
    },
    isFreemium() {
      return this.user && this.user.plan.title === 'Freemium'
    },
  },
  methods: {
    async submit() {
      const {data} = await this.$store.dispatch('projects/create', this.form)
      if (data.status !== 'error') {
        this.showNewModal = false;
        this.form.title = ''
        this.$router.push({name: 'Sections', params: {project_id: data.result.id}})
      }

    },
    edit(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.showEditModal = true;
    },
    async update() {
      const {data} = await this.$store.dispatch('projects/update', {
        title: this.selected.title,
        project_id: this.selected.id
      })
      if (data.status !== 'error') {
        this.showEditModal = false;
        this.selected = null
      }
    },
    showMembers(project_id) {
      this.$store.dispatch('members/list', {
        project_id: project_id,
      })
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('projects/delete', {
            project_id: id,
          })
        }
      })


    },
  },
  created() {
    this.$store.dispatch('projects/init')
  }
}
</script>
<style lang="scss">
.customModal {
  background-color: rgba(0, 0, 0, 0.7);
}

.item:hover {

}

.actions {
  button {
    &:hover {
      color: white;
    }
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
