<template>
  <div class="lg:flex items-center justify-between" v-if="plans">
    <div class="lg:w-1/2 w-full">
      <p class="text-base leading-4 text-gray-600 dark:text-gray-200">Choose your plan</p>
      <h1 role="heading" class="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800 dark:text-white">Our
        pricing plan</h1>
      <p role="contentinfo" class="text-base leading-5 mt-5 text-gray-600 dark:text-gray-200">We’re working on a suit
        of tools to make managing complex systems easier, for everyone. we can’t wait to hear what you
        think</p>
      <div class="w-56">
        <button class="bg-gray-100 dark:bg-gray-800 shadow rounded-full flex items-center mt-10 rounded-full">
          <template v-if="planType === 'monthly'">
            <div
                @click="planType = 'annually'"
                class="bg-gray-100 dark:bg-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none text-base leading-none text-gray-600 dark:text-gray-200 rounded-full py-4 px-6 mr-1">
              Annually
            </div>
            <div

                class="bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none text-base leading-none text-white rounded-full py-4 px-6">
              Monthly
            </div>
          </template>
          <template v-if="planType === 'annually'">
            <div

                class="bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none text-base leading-none text-white rounded-full py-4 px-6">
              Annually
            </div>
            <div
                @click="planType = 'monthly'"
                class="bg-gray-100 dark:bg-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none text-base leading-none text-gray-600 dark:text-gray-200 rounded-full py-4 px-6 mr-1">
              Monthly
            </div>

          </template>
        </button>
      </div>
    </div>

    <div class="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8" role="list">
      <div role="listitem" class="bg-white dark:bg-gray-800 cursor-pointer shadow rounded-lg p-8 mb-5 relative z-30"
           v-for="item in plans" :key="item.id"
           :class="{'border-l-4 border-purple-600' : item.id === user.plan.id}"
      >
        <div class="md:flex items-center justify-between">
          <h2 class="text-2xl font-semibold leading-6 text-gray-800 dark:text-white">{{ item.title }}</h2>
          <p class="text-2xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800 dark:text-white">
            ${{ priceCalc(item.price) }}</p>
        </div>
        <p class="md:w-80 text-base font-light leading-6 mt-4 text-gray-600 dark:text-gray-200">{{
            item.description
          }}</p>
        <div class="flex mt-3 text-sm dark:text-gray-200">
          <div class="mr-2">
            <span class="font-medium mr-1 text-purple-600 dark:text-gray-200">{{ item.project_limit }}</span>
            <span class="font-light">Project,</span>
          </div>
          <div class="mr-2">
            <span class="font-medium mr-1 text-purple-600 dark:text-gray-200">{{ item.section_limit }}</span>
            <span class="font-light">Section, </span>
          </div>
          <div class="mr-2">
            <span class="font-medium mr-1 text-purple-600 dark:text-gray-200">{{ item.member_limit }}</span>
            <span class="font-light">Member User</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Plans',
  data() {
    return {
      planType: 'monthly'
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    plans() {
      return this.$store.getters["plans"];
    },
  },
  methods: {
    priceCalc(price) {
      return this.planType === 'monthly' ? price : (price * 11).toFixed(2)
    }
  },
  async created() {
    await this.$store.dispatch('projects/init')
    await this.$store.dispatch('plans')
  }
}
</script>
<style lang="scss">

</style>
