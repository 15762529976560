import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        list: [],
        current: null
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_CURRENT(state, payload) {
            state.current = payload;
        },
    },
    actions: {
        init({dispatch}) {
            dispatch('list');
        },
        list({commit}) {
            commit('SET_LOADING', true, {root: true})
            ApiService.get(`/design-reports/`).then((res) => {
                commit("SET_LIST", res.data.result.reverse());
                commit('SET_LOADING', false, {root: true})
            })
        },
        get({commit}, {report_id,}) {
            commit('SET_LOADING', true, {root: true})
            ApiService.get(`/design-reports/${report_id}/`).then((res) => {
                commit("SET_CURRENT", res.data.result);
            })
        },
        async create({commit, dispatch}, payload) {

            commit('SET_ERROR', null, {root: true})
            return await ApiService.post(`/design-reports/`, payload.params).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async update({commit, dispatch}, payload) {
            commit('SET_LOADING', true, {root: true})
            return await ApiService.patch(`/design-reports/${payload.report_id}/`, payload).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async delete({commit, dispatch}, {report_id}) {
            commit('SET_LOADING', true, {root: true})
            return await ApiService.delete(`/design-reports/${report_id}`).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list')
                }
                return res
            })
        },
    },
    getters: {
        list(state) {
            return state.list;
        },
        current(state) {
            return state.current;
        }
    }
};
