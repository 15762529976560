<template>
  <header v-if="isLogin">
    <nav class="bg-white border-gray-200 pl-4 pr-2 py-2.5 dark:bg-gray-800">
      <div class="flex flex-wrap justify-between items-center">
        <div class="flex justify-start items-center">
          <div class="self-center font-semibold whitespace-nowrap">
            <router-link to="/" class="font-black text-lg text-gray-600 dark:text-white">
              <span class="font-thin">UX</span>
              <span class="font-light">REPORT</span>
            </router-link>
            <div class="font-thin text-xs text-gray-900 dark:text-white">version: close beta</div>
          </div>
        </div>
        <div class="text-center" v-if="page !== 'Home'">
          <div v-if="project" class="font-thin text-md">
            <router-link :to="{ name: 'Sections', params: { project_id: project.id}}"
                         class="text-gray-900 dark:text-blue-500">{{ project.title }}
            </router-link>
          </div>
          <div v-if="section && page==='Findings'" class="font-light text-sm dark:text-gray-100">{{
              section.title
            }}
          </div>
        </div>

        <div class="flex flex-shrink-0 items-center space-x-4 text-black pr-3">

          <div class="hidden md:flex flex-col items-end dark:text-white ">
            <div class="text-sm font-regular ">{{ user.user.full_name }}</div>
            <router-link :to="{ name: 'Plans'}" class="text-xs font-light hover:text-gray-800">{{ user.plan.title }} /
              <span
                  class="font-thin"> {{ user.ended_at | moment('DD MMM') }}</span></router-link>
          </div>

          <button @click="theme()" id="theme-toggle" data-tooltip-target="tooltip-toggle" type="button"
                  class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 text-sm p-2.5">
            <svg v-if="themeColor !== 'dark'" aria-hidden="true" id="theme-toggle-dark-icon" class="w-5 h-5"
                 fill="currentColor" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
            </svg>
            <svg v-if="themeColor === 'dark'" aria-hidden="true" id="theme-toggle-light-icon" class="w-5 h-5"
                 fill="currentColor" viewBox="0 0 20 20"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
            <span class="sr-only">Toggle dark mode</span>
          </button>

          <button @click="logout()" type="button"
                  class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 text-sm p-2.5">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
            </svg>
          </button>
          <button @click="toggleFullScreen()" type="button"
                  class="hidden md:block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 text-sm p-2.5">

            <svg v-if="!isFullsize" class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"></path>
            </svg>
            <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"></path>
            </svg>

          </button>

        </div>

      </div>
    </nav>

  </header>
</template>
<script>

export default {
  data() {
    return {
      isFullsize: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    isLogin() {
      return this.$store.getters["isLogin"];
    },
    themeColor() {
      return this.$store.getters["theme"];
    },
    project() {
      return this.$store.getters["projects/current"];
    },
    section() {
      return this.$store.getters["sections/current"];
    },
    page() {
      return this.$route.name
    },
  },
  methods: {
    theme() {
      if (this.themeColor === 'dark') {
        this.$store.commit('SET_THEME', 'light')
      } else {
        this.$store.commit('SET_THEME', 'dark')
      }
    },
    logout() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location = '/login'
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.isFullsize = true
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
        this.isFullsize = false
      }
    }
  },
};
</script>
<style lang="scss">

</style>

