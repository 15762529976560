import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        categories: null,
        labels: null,
    },
    mutations: {
        SET_CATEGORIES(state, payload) {
            state.categories = payload;
        },
        SET_LABELS(state, payload) {
            state.labels = payload;
        },
    },
    actions: {
        async init({commit, dispatch}, {project_id}) {
            commit('SET_LOADING', true, {root: true})
            await dispatch('categoriesAndLabels', {project_id})
            commit('SET_LOADING', false, {root: true})
        },
        async categoriesAndLabels({dispatch}, {project_id}) {
            await dispatch('categories', {project_id})
            await dispatch('labels', {project_id})
        },
        async categories({commit}, {project_id}) {
            commit('SET_LOADING', true, {root: true})
            await ApiService.get(`/projects/${project_id}/categories/`).then((res) => {
                commit("SET_CATEGORIES", res.data.result);
                commit('SET_LOADING', false, {root: true})
            })
        },
        async addCategory({dispatch}, {project_id, title}) {
            await ApiService.post(`/projects/${project_id}/categories/`, {title: title})
            await dispatch('categories', {project_id})
        },
        async labels({commit}, {project_id}) {
            commit('SET_LOADING', true, {root: true})
            await ApiService.get(`/projects/${project_id}/labels/`).then((res) => {
                commit("SET_LABELS", res.data.result);
                commit('SET_LOADING', false, {root: true})
            })
        },
        async addLabel({dispatch}, {project_id, title}) {
            await ApiService.post(`/projects/${project_id}/labels/`, {title: title})
            await dispatch('labels', {project_id})
        },
    },
    getters: {
        categories(state) {
            return state.categories;
        },
        labels(state) {
            return state.labels;
        },
    }
};
