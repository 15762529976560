import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        list: [],
        current: null
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_CURRENT(state, payload) {
            state.current = payload;
        },
    },
    actions: {
        init({dispatch}, {project_id}) {
            dispatch('list', {project_id});
        },
        list({commit}, {project_id}) {
            commit('SET_LOADING', true, {root: true})
            ApiService.get(`/projects/${project_id}/sections/`).then((res) => {
                commit("SET_LIST", res.data.result);
                commit('SET_LOADING', false, {root: true})
            })
        },
        get({commit}, {project_id, section_id}) {
            commit('SET_LOADING', true, {root: true})
            ApiService.get(`/projects/${project_id}/sections/${section_id}`).then((res) => {
                commit("SET_CURRENT", res.data.result);
            })
        },
        async create({commit, dispatch}, payload) {

            commit('SET_ERROR', null, {root: true})
            return await ApiService.post(`/projects/${payload.project_id}/sections/`, payload.params).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async update({commit, dispatch}, payload) {
            commit('SET_LOADING', true, {root: true})
            return await ApiService.patch(`/projects/${payload.project_id}/sections/${payload.section_id}/`, payload).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', payload)
                }
                return res
            })
        },
        async delete({commit, dispatch}, {project_id, section_id}) {
            commit('SET_LOADING', true, {root: true})
            return await ApiService.delete(`/projects/${project_id}/sections/${section_id}`).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', {project_id, section_id})
                }
                return res
            })
        },
    },
    getters: {
        list(state) {
            return state.list;
        },
        current(state) {
            return state.current;
        }
    }
};
