import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        isShow: false,
        list: null,
        project_id: null
    },
    mutations: {
        SET_IS_SHOW(state, payload) {
            state.isShow = payload;
        },
        SET_PROJECT_ID(state, payload) {
            state.project_id = payload;
        },
        SET_MEMBERS(state, payload) {
            state.list = payload;
        },
    },
    actions: {
        async list({commit}, {project_id}) {
            commit('SET_LOADING', true, {root: true})
            commit("SET_IS_SHOW", false);
            ApiService.get(`/projects/${project_id}/members/`).then((res) => {

                commit("SET_PROJECT_ID", project_id);
                commit("SET_MEMBERS", res.data.result);
                commit("SET_IS_SHOW", true);
                commit('SET_LOADING', false, {root: true})
            })
        },
        async create({state, commit, dispatch}, payload) {
            const project_id = state.project_id;
            commit('SET_ERROR', null, {root: true})
            return await ApiService.post(`/projects/${project_id}/members/`, payload).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', {project_id})
                }
                return res
            })
        },
        async delete({state, commit, dispatch}, {member_id}) {
            const project_id = state.project_id;
            commit('SET_LOADING', true, {root: true})
            return await ApiService.delete(`/projects/${project_id}/members/${member_id}`).then((res) => {
                commit('SET_LOADING', false, {root: true})
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    dispatch('list', {project_id})
                }
                return res
            })
        },
    },
    getters: {
        isShow(state) {
            return state.isShow
        },
        list(state) {
            return state.list
        },
    }
};
