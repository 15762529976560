<template>
  <div class="magic">
    <!-- Main modal -->
    <transition>
      <div v-if="showEditModal" class="modal">
        <div class="relative w-full max-w-2xl max-h-full">
          <!-- Modal content -->
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Edit Type
              </h3>
              <button type="button"
                      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="defaultModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only" @click="showEditModal = false">Close modal</span>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
              <!-- Element types change -->
              <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an
                type</label>
              <select id="countries"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value="image" :selected="result.elements[selectedIndex].type">Image</option>
                <option value="text" :selected="result.elements[selectedIndex].type">Text</option>
                <option value="icon" :selected="result.elements[selectedIndex].type">Icon</option>
              </select>


            </div>
            <!-- Modal footer -->
            <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button @click="showEditModal = false" data-modal-hide="defaultModal" type="button"
                      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Update Element Type
              </button>
            </div>
          </div>
        </div>
      </div>

    </transition>
    <div v-if="report" class="grid grid-cols-3">
      <div class="col-span-1 mt-4">
        <div
            class="text-sm font-medium text-center text-gray-500 bg-white border-b border-gray-200 mr-8 dark:bg-gray-800">
          <ul class="flex flex-wrap -mb-px cursor-pointer">
            <li class="mr-2">
              <span

                  @click="resultType = 'elements'"
                  class="inline-block p-4 border-b-2 border-transparent rounded-t-lg"
                  :class="{ 'border-blue-500 text-blue-500': resultType === 'elements' }"
              >
                Elements
              </span>
            </li>
            <li class="mr-2">
              <span
                  @click="resultType = 'thumbZone'"
                  class="inline-block p-4 border-b-2 border-transparent rounded-t-lg"
                  :class="{ 'border-blue-500 text-blue-500': resultType === 'thumbZone' }"
              >Thumb Zone</span>
            </li>
            <li class="mr-2">
              <span
                  @click="resultType = 'heatmap'"
                  class="inline-block p-4 border-b-2 border-transparent rounded-t-lg"
                  :class="{ 'border-blue-500 text-blue-500': resultType === 'heatmap' }"
              >
                Heatmap
              </span>
            </li>

          </ul>
        </div>
        <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300 mr-8">
          <div class="relative masks">
            <div v-show="resultType === 'heatmap'" class="heatmap" ref="heatmap"></div>
            <img :src="report.image" ref="screenshot" @load="imageLoaded">
            <svg v-if="resultType === 'thumbZone'" class="thumbZone" preserveAspectRatio="none" width="375" height="667"
                 viewBox="0 0 375 667" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2_12)">
                <rect width="375" height="667" fill="white"></rect>
                <rect width="375" height="667" fill="#FF0000" fill-opacity="0.4"></rect>
                <path
                    d="M79.4236 78.708C29.5576 122.3 5.69705 178.943 0 201.815V667H315.684C322.721 665.318 340.214 658.726 353.887 645.809C367.56 632.893 373.66 619.573 375 614.528V0H234.249C203.418 8.07262 129.29 35.1159 79.4236 78.708Z"
                    fill="#F8D626" fill-opacity="0.5" style="fill: #ff9600;"></path>
                <path
                    d="M300 130.532C276.689 128.542 192.568 121.575 110.473 169.349C26.9595 239.418 2.02703 310.018 0 336.56V667H234.122C251.351 658.706 293.514 634.553 324.324 604.296C355.135 574.039 370.946 546.568 375 536.615V137.499C357.77 135.841 318.649 132.125 300 130.532Z"
                    fill="#31D33E" fill-opacity="0.6" style="fill: green;"></path>
              </g>
              <defs>
                <clipPath id="clip0_2_12">
                  <rect width="375" height="667" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <template v-if="resultType === 'elements'">
              <div v-for="(item, index) in report.result.elements" :key="index"
                   class="mask"
                   :class="item.type"
                   :style="'width: '+item.coordinates.w+'px; height: '+item.coordinates.h+'px; top: '+item.coordinates.y+'px; left: '+item.coordinates.x+'px;'">
                <span class="pin"></span>
              </div>
            </template>
          </div>
        </div>

      </div>
      <div class="col-span-2">
        <div>
          <div
              class=" mt-4 flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300 mr-8">
            {{ report.result.stats.texts }}
          </div>
          <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-4">
            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
                 v-for="(item, index) in report.result.stats.density"
                 :key="index"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0"><span class="text-2xl leading-none text-gray-600 capitalize"> {{
                    item
                  }} </span>
                  <h3 class="text-base font-light text-gray-400">Position of <span class="capitalize">{{ index }}</span>
                    Density
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-4 xl:grid-cols-4 gap-4">
            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
                 v-for="(item, index) in report.result.stats.coverage"
                 :key="index"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0"><span class="text-2xl leading-none text-gray-600 capitalize"> %{{
                    item
                  }} </span>
                  <h3 class="text-base font-light text-gray-400"><span class="capitalize">{{ index }}</span> Coverage
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-4">
            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
                 v-for="(item, index) in report.result.stats.type_count"
                 :key="index"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0"><span class="text-2xl leading-none text-gray-600 capitalize"> {{
                    item
                  }} </span>
                  <h3 class="text-base font-light text-gray-400"><span class="capitalize">Total {{
                      index
                    }} Element</span>
                  </h3></div>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4">
            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
                 v-if="colorGroup.text"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0"><span class="text-2xl leading-none text-gray-600 capitalize">
                  {{ colorGroup.text.length }} Group
                </span>
                  <h3 class="text-base font-light text-gray-400"><span class="capitalize">
                     Text Colors
                  </span>
                  </h3>
                </div>
              </div>
            </div>
            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300"
                 v-if="colorGroup.background"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0"><span class="text-2xl leading-none text-gray-600 capitalize">
                  {{ colorGroup.background.length }} Group
                </span>
                  <h3 class="text-base font-light text-gray-400"><span class="capitalize">
                     Text Background Colors
                  </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-4">
            <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
              <h3 class="text-xl leading-none text-gray-600 capitalize mb-3">
                Text Colors
              </h3>
              <magic-colors :colors="report.result.stats.colors.text" type="text"></magic-colors>
            </div>

            <div class="bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
              <h3 class="text-xl leading-none text-gray-600 capitalize mb-3 ">
                Background Colors
              </h3>
              <magic-colors :colors="report.result.stats.colors.background" type="background"></magic-colors>
            </div>

            <div class="flex bg-white border-b p-4 sm:p-6 xl:p-8 text-gray-900 dark:bg-gray-800 dark:text-gray-300">
              <div class="flex flex-wrap">
                <h3 class="text-xl leading-none text-gray-600 capitalize ">
                  Dominant Colors
                  <div class="text-2xl text-gray-600 capitalize flex flex-wrap mt-2">
                    <div class="h-20 w-20 rounded relative mr-2 mb-2" :style="'background-color:'+item.color+';'"
                         v-for="(item, index) in report.result.stats.colors.dominant" :key="index">
                  <span
                      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm text-white">{{
                      item.percentage
                    }}%</span>
                    </div>
                  </div>
                </h3>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import h337 from 'heatmapjs';
import MagicColors from "../../components/DesignReports/Colors.vue";

export default {
  name: 'DesignReportsDetail',
  components: {MagicColors},
  data() {
    return {
      showEditModal: false,
      resultType: 'elements',
      colorGroup: {
        text: null,
        background: null,
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["user"];
    },
    report() {
      return this.$store.getters["design_reports/current"];
    },
  },
  methods: {
    calculateCoordinates(element, newWidth) {
      const originalWidth = this.report.result.info.width;
      const originalHeight = this.report.result.info.height;
      const {x, y, w, h} = element.coordinates;

      const scaleX = newWidth / originalWidth;
      const newHeight = originalHeight * scaleX;
      const scaleY = newHeight / originalHeight;

      const newX = Math.round(x * scaleX);
      const newY = Math.round(y * scaleY);
      const newW = Math.round(w * scaleX);
      const newH = Math.round(h * scaleY);

      return {
        x: newX,
        y: newY,
        w: newW,
        h: newH
      };
    },
    resultInit() {
      const screenshotWidth = this.$refs.screenshot.clientWidth;
      const screenshotHeight = this.$refs.screenshot.clientHeight;

      this.$refs.heatmap.style.height = `${screenshotHeight}px`;
      this.$refs.heatmap.style.width = `${screenshotWidth}px`;

      const newElements = JSON.parse(JSON.stringify(this.report.result.elements));
      newElements.forEach((element) => {
        const newCoordinates = this.calculateCoordinates(element, screenshotWidth);
        element.coordinates = newCoordinates;
      });

      this.report.result.elements = newElements;

      this.createHeatmap();
    },
    createHeatmap() {
      const heatmap = h337.create({
        container: this.$refs.heatmap,
      });

      const data = {
        max: 1,
        min: 0,
        data: []
      };

      this.report.result.elements.forEach((element) => {
        const {x, y, w, h} = element.coordinates;

        const newX = Math.round(x);
        const newY = Math.round(y);
        const newW = Math.round(w);
        const newH = Math.round(h);

        //calculate center
        const centerX = newX + (newW / 2);
        const centerY = newY + (newH / 2);

        data.data.push({
          x: centerX,
          y: centerY,
          value: 1,
          radius: 50
        });
      });

      heatmap.setData(data);
    },
    imageLoaded() {
      this.$nextTick(() => {
        this.resultInit();
        this.$store.commit('SET_LOADING', false, {root: true})
      })
    },
  },
  async created() {
    await this.$store.dispatch('design_reports/get', {
      report_id: this.$route.params.report_id,
    })
  }
};
</script>
<style lang="scss">
.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.masks {
  overflow: hidden;


  &:hover {
    .mask {
      &.text {
        border: 2px solid rgba(52, 152, 219, 1.0);
      }

      &.icon {
        border: 2px solid rgba(46, 204, 113, 1.0);
      }

      &.image {
        border: 2px solid rgba(231, 76, 60, 1.0);
      }
    }
  }

}

.mask {
  position: absolute;
  display: inline-block;
  background-size: 100% auto;
  z-index: 2;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  transition: all 300ms ease;

  &.text {
    .pin {
      background-color: rgba(52, 152, 219, 1.0);
    }
  }

  &.icon {
    .pin {
      background-color: rgba(46, 204, 113, 1.0);
    }
  }

  &.image {
    .pin {
      background-color: rgba(231, 76, 60, 1.0);
    }
  }

  .pin {
    box-sizing: content-box;
    content: "";
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: -7px;
    top: -7px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.8);
  }


}

.thumbZone {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.heatmap {
  position: absolute !important;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}
</style>
