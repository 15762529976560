import ApiService from "../../services/api";

export default {
    namespaced: true,
    state: {
        report: null,
    },
    mutations: {
        SET_REPORT(state, payload) {
            state.report = payload;
        },
    },
    actions: {
        async get({commit}, {project_id}) {
            commit('SET_LOADING', true, {root: true})

            return await ApiService.get(`/projects/${project_id}/reports/`).then((res) => {
                if (res.data.status === 'error') {
                    commit('SET_ERROR', res.data.result, {root: true})
                } else {
                    commit('SET_REPORT', res.data.result[0])
                }
                commit('SET_LOADING', false, {root: true})
                return res
            })

        },
    },
    getters: {
        report(state) {
            return state.report;
        }
    }
};
