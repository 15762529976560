<template>
  <div data-page-contents class="flex flex-wrap">

    <div class="md:w-5/12">
      <div class="bg-white p-4 shadow mt-4 dark:bg-gray-700 relative ml-4">
        <div class="grid gap-4 mb-4 sm:grid-cols-2 ">
          <div class="sm:col-span-2">
            <label for="labels"
                   class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Labels</label>
            <div v-if="labels">
              <multiselect
                  id="category"
                  v-model="content.labels"
                  :options="labels"
                  :searchable="true"
                  :taggable="true"
                  :multiple="true"
                  @tag="addLabel"
                  label="title"
                  track-by="id"
                  placeholder="Pick a labels"></multiselect>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="content"
                   class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content</label>
            <textarea id="content" rows="4"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Write content here" v-model="content.content"></textarea>
          </div>
          <div class="sm:col-span-2">
            <label for="insights" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Insights</label>
            <textarea id="insights" rows="4"
                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Write recommendation here" v-model="content.insight"></textarea>
          </div>

          <div class="sm:col-span-2">

            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sentiment</label>

            <div class="flex  items-center">
              <div class="flex items-center mr-4" v-for="item in sentiments" :key="item.id">
                <input :id="'sentiment-'+ item.id" type="radio" v-model="content.sentiment" name="type"
                       :value="item.id"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label :for="'sentiment-'+ item.id"
                       class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
              </div>
            </div>
          </div>

          <div class="sm:col-span-2">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rating of Issue</label>
            <div class="flex items-center">
              <div class="flex items-center mr-4" v-for="item in ratings" :key="item.id">
                <input :id="'rating-'+ item.id" type="radio" v-model="content.rating" name="rating" :value="item.id"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label :for="'rating-'+ item.id"
                       class="ml-2 text-sm font-light text-gray-900 dark:text-gray-300">{{ item.title }}</label>
              </div>
            </div>
          </div>

        </div>
        <div class="flex justify-between">
          <button v-if="isEdit" @click="content = empty, isEdit = false" type="button"
                  class="text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
            Quit Edit Mode
          </button>
          <button @click="isEdit ? update() : create()"
                  type="button"
                  class="text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            {{ isEdit ? 'UPDATE' : 'CREATE' }}
          </button>
        </div>
      </div>
    </div>
    <div class="md:w-7/12">
      <div class="contents">
        <div class="flex flex-wrap">
          <div v-for="item in itemsReversed" :key="item.id" class="cursor-pointer w-full p-4 md:w-6/6 lg:w-6/6"
               :data-content-id="item.id"
               @click="edit(item.id)"
          >
            <div
                class="content-item transition-all ease-linear duration-200 block bg-white shadow-md hover:shadow-xl overflow-hidden hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                :class="{'selected-content dark:bg-purple-900 dark:hover:bg-purple-800' : selectedContent=== item.id}"
            >

              <div class="p-4 pb-2">
                <div class="flex justify-between">
                      <span class="flex-1">
                       <span v-for="(label, index) in item.labels_str" :key="index"
                             class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                         #{{ label }}</span>
                      </span>
                  <div>

                  </div>

                  <span
                      class="bg-gray-100 text-gray-400 text-xs font-semibold px-2 py-1 rounded dark:bg-gray-700 dark:text-gray-300 ">{{
                      item.created_at | moment('DD/MM/YYYY')
                    }}</span>

                  <span
                      @click="destroy(item.id)"
                      class="edit-content bg-red-700 text-white text-xs font-semibold px-2 py-1 rounded dark:bg-red-700 dark:text-gray-300 ml-2 cursor-pointer">DELETE</span>
                </div>
                <h2 class="mt-2 mb-2 text-sm font-bold dark:text-white">
                  {{ item.content }}
                </h2>
                <p class="text-sm font-light dark:text-white">
                  {{ item.insight }}
                </p>
              </div>
              <div class="p-3 border-t text-xs text-gray-700  dark:border-gray-900">
                <div class="flex flex-wrap">
                  <div class="mr-3">
                    <span class="ml-2 text-xs font-light dark:text-white">Sentiment</span>
                    <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.sentiment_str }}</span>
                  </div>
                  <div class="mr-3">
                    <span class="ml-2 text-xs font-light dark:text-white">Rating</span>
                    <span class="block ml-2 text-sm font-bold dark:text-white">{{ item.rating_str }}</span>
                  </div>
                  <div class="flex-1 text-right pr-2">
                    <span class="ml-2 text-xs font-thin dark:text-white">Report by</span>
                    <span class="block ml-2 text-xs font-light dark:text-white">{{ item.creator_full_name }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>


export default {
  name: 'Contents',
  data() {
    return {
      createOrUpdate: false,
      isEdit: false,
      selectedContent: null,
      withPin: true,
      empty: {
        axis_x: 0,
        axis_y: 0,
        sentiment: 1,
        rating: 1,
        content: '',
        insight: '',
        labels: null
      },
      content: {
        axis_x: 0,
        axis_y: 0,
        sentiment: 1,
        rating: 1,
        content: '',
        insight: '',
        labels: null
      },
    }
  },
  computed: {
    items() {
      return this.$store.getters["contents/list"];
    },
    labels() {
      return this.$store.getters["tags/labels"];
    },
    itemsReversed() {
      return JSON.parse(JSON.stringify(this.items)).reverse()
    },
    sentiments() {
      return this.$store.getters['sentiments'];
    },
    ratings() {
      return this.$store.getters['ratings'];
    },
    efforts() {
      return this.$store.getters['efforts'];
    }
  },
  methods: {

    async create() {
      const self = this;

      if (!this.validateData()) return;

      await this.$store.dispatch('contents/create', {
        params: self.content,
        project_id: this.$route.params.project_id,
        section_id: this.$route.params.section_id,
      })
      this.createOrUpdate = false;
      this.content = this.empty;
    },
    async edit(id) {

      let content = this.items.find((item) => item.id === id)
      content = JSON.parse(JSON.stringify(content));

      let labels = [];
      this.labels.forEach((item) => {
        if (content.labels.includes(item.id)) {
          labels.push(item)
        }
      })
      content.labels = labels;

      this.isEdit = true;
      this.createOrUpdate = true;
      this.selectedContent = content.id;
      this.content = content;
    },
    async update() {
      const self = this;

      if (!this.validateData()) return;

      await this.$store.dispatch('contents/update', {
        params: self.content,
        project_id: this.$route.params.project_id,
        section_id: this.$route.params.section_id,
        content_id: self.selectedContent,
      })
      this.createOrUpdate = false;
      this.isEdit = false;
      this.content = this.empty;
    },
    async destroy(id) {

      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch('contents/delete', {
            project_id: this.$route.params.project_id,
            section_id: this.$route.params.section_id,
            content_id: id,
          })

          this.createOrUpdate = false;
          this.content = this.empty;
          this.selectedFinding = null;
          this.isEdit = false;
        }
      })


    },
    async addLabel(title) {
      await this.$store.dispatch('tags/addLabel', {
        project_id: this.$route.params.project_id,
        title: title
      }).then(() => {
        const labels = JSON.parse(JSON.stringify(this.labels))
        const label = labels.find((item) => item.title === title)
        this.content.labels = [...this.content.labels, label];
      })
    },
    validateData() {
      if (!this.content.labels) {
        this.$store.commit('SET_ERROR', {
          "Ups": ["Please fill in all fields."]
        }, {root: true})
        return
      }
      return true
    }
  },
  created() {
    this.$store.dispatch('contents/init', {
      project_id: this.$route.params.project_id,
      section_id: this.$route.params.section_id,
    })
    this.$store.dispatch('tags/init', {
      project_id: this.$route.params.project_id,
    })
    this.$store.dispatch('projects/get', {
      project_id: this.$route.params.project_id,
    })

    this.$store.dispatch('sections/get', {
      project_id: this.$route.params.project_id,
      section_id: this.$route.params.section_id,
    })

  }
}
</script>
<style lang="scss" scoped>
.pins {
  width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 7px;
  touch-action: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  cursor: copy;

}

.pin {
  position: absolute;
  width: 40px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: #673ab7;
    border-color: white;
    animation: pulse 1s infinite;
  }

  &.new {
    z-index: 99;
    background-color: #6cc800;
    animation: pulse 1s infinite;
  }
}

.customModal {
  background-color: rgba(0, 0, 0, 0.7);
}

.edit-content {
  display: none;
}

.selected-content, .content-item:hover {
  .edit-content {
    display: inline-block;
  }
}

.contents {
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.select-pin-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #b3b3b3;
  z-index: 9;
  border: 5px dashed rgba(255, 255, 255, 0.4);
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
