<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <h2 class="mt-6 text-center text-5xl tracking-tight text-gray-900 dark:text-gray-200 mb-10">
        <span class="font-light">UX</span>
        <span class="font-thin">REPORT</span>
      </h2>
      <div
          class="w-full bg-white border-b dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Create and account
          </h1>
          <div
              v-for="(items,title) in error"
              :key="title"
              class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded dark:bg-red-200 dark:text-red-800"
              role="alert">
            <div class="capitalize">{{ title }}</div>
            <p v-for="(item, index) in items" :key="index">{{ item }}</p>
          </div>
          <form class="space-y-4 md:space-y-6" action="#" autocomplate="off" @submit.prevent="submit">
            <div>
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display
                name</label>
              <input type="text" name="name" id="name"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required
                     v-model="form.name"
              >
            </div>
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
              <input type="email" name="email" id="email"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     placeholder="name@company.com" required
                     v-model="form.email"
              >
            </div>
            <div>
              <label for="password"
                     class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input type="password" name="password" id="password" placeholder="••••••••"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required
                     v-model="form.password1"
              >
            </div>
            <div>
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm
                password</label>
              <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••"
                     class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                     required
                     v-model="form.password2"
              >
            </div>
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="terms" aria-describedby="terms" type="checkbox"
                       class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                       required="">
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                    href="/terms-of-conditions.html" target="_blank">Terms of
                  Conditions</a> and <a
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                    href="/privacy-policy.html" target="_blank">Privacy Policy</a></label>
              </div>
            </div>
            <button type="submit"
                    class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
              Create an account
            </button>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Already have an account?
              <router-link to="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login
                here
              </router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApiService from "../../services/api";

export default {
  name: "Register",
  data() {
    return {
      error: null,
      form: {
        name: "",
        email: "",
        password1: "",
        password2: ""
      }
    };
  },
  methods: {
    submit() {

      const self = this;
      self.error = null;
      self.$store.commit("SET_LOADING", true);

      ApiService.post("auth/registration/", self.form).then(() => {
        self.$store.commit("SET_LOADING", false);
        self.$swal.fire({
          icon: "success",
          title: "Confirm E-mail Address",
          text: "You can verify your email address with the link sent."
        }).then((result) => {
          if (result.isConfirmed) {
            window.location = "/login";
          }
        });

      })
          .catch((err) => {
            self.error = err.response.data.result;
            self.$store.commit("SET_LOADING", false);
          })
    }
  },
  created() {
    this.$store.commit("SET_LOADING", false);
  }
};
</script>

<style scoped>

</style>
